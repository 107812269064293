<template>
  <div class="background">
    <div class="logobox">
      <div class="logo"> </div>
    </div>
    <div class="loginbox dp-f">
      <div class="banner">
        <img src="@/assets/img/login/banner2.png">
      </div>
      <div class="w100 h100 dp-fc" style="padding-top: 120px;">
        <loginc v-if="state.key == 'login'" @setKey="key => state.key = key"></loginc>
        <password v-if="state.key == 'password'" @setKey="key => state.key = key"></password>
      </div>
    </div>
    <div class="dp-fc copyright">
      Copyright © 2022-2024 &nbsp; &nbsp; 一览数字科技（江苏）有限公司  &nbsp;&nbsp;
      <a href="https://beian.miit.gov.cn" target="_blank" style="color: #a7adba;">备案/许可证编号：苏ICP备2022004474号-2 </a>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, unref } from 'vue'
import loginc from './components/loginc.vue'//登录页
import password from './components/password.vue'//找回密码
const state = reactive({
  key: 'login',
})
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss"; //element 样式重置

.background {
  background: #FDFDFD url('~@/assets/img/login/bg.png') no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  padding-bottom: 56px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;

  .logobox {
      padding: 20px 27px;
      margin: 0 auto;
      max-width: 1440px;
      min-width: 1280px;
      position: relative;
      overflow: auto;
      .logo {
        display: inline-block;        
        width: 135px;
        height: 33px;
        background: url('~@/assets/img/nav/title_icon2.png') left/70% no-repeat;
      }
  }

  .loginbox {
    // position: relative;
    margin: 0 auto;
    max-width: 1440px;
    min-width: 1280px;
    min-height: calc(100% - 140px);
    padding: 40px 0px;
    overflow: hidden;
    .banner {
      // height: 100%;
      max-width: 1000px;
      min-width: 700px;
      // width: 50vw;
      display: flex;
      align-items: center;
      img {
        margin: auto;
        width: 700px;
      }
    }
  }
  .copyright {
    font-size: small;
    color: #a7adba;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    line-height: 40px;
    // min-height: 20px;
  }
}

//设置input框记住密码背景颜色 通过延迟时间让默认颜色无法填充
::v-deep input:-internal-autofill-selected {
  background-color: transparent !important;
  transition: background-color 50000000s ease-in-out 0s;
}
</style>